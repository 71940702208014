import React from "react"
import { graphql } from "gatsby"
import Styles from './article.module.css'

const Article = ({ data: { prismicArticle } }) => {
  const { data } = prismicArticle
  const raw = data.body[0].items[1].answer.raw[1].text
  const table = rawToTable(raw)
  return (
    <React.Fragment>
      <article>
        <h1>{data.title.text}</h1>
        <section dangerouslySetInnerHTML={{ __html: data.content.html }} />
        <section>
          <h2>Experiment</h2>
          <p>Parsing raw data from prismic PRE element into a table:</p>
          <Table data={table}/>
        </section>
      </article>
    </React.Fragment>
  )
}

const rawToTable = (raw) => {
  const table = []
  const lines = raw.split('\n')
  const headers = lines[0].split('|')
  lines.shift()
  lines.forEach((line) => {
    const row = {}
    const current = line.split('|')
    headers.forEach((header, field) => {
      row[header] = current[field]
    })
    table.push(row)
  })
  return table
}

const TableRow = ({row}) => {
  const headers = Object.keys(row)
  return (
    <tr>
      {headers.map((header, i) => <td key={i}>{row[header]}</td>)}
    </tr>
  )
}

const Table = ({data}) => {
  const rows = data.slice()
  const headers = Object.keys(rows[0])
  return (
    <table>
      <thead>
        <tr>
          {headers.map(key => <th key={key}>{key}</th>)}
        </tr>
      </thead>
      <tbody>
        {rows.map((row, i) => {
          return (
            <TableRow key={i} headers={headers} row={row} />
          )
        })}
      </tbody>
    </table>
  )
}

export default Article

export const pageQuery = graphql`
  query ArticleBySlug($uid: String!) {
    prismicArticle(uid: { eq: $uid }) {
      uid
      first_publication_date
      last_publication_date
      slugs
      data {
        date
        title {
          text
        }
        content {
          html
        }
        image {
          dimensions {
            width
            height
          }
          alt
          copyright
          url
          localFile {
            id
            uid
            url
            size
            prettySize
            relativePath
            name
          }
        }
        body {
          slice_type
          items {
            question {
              text
            }
            answer {
              html
              raw {
                type
                text
                spans {
                  start
                  end
                  type
                  data {
                    link_type
                    name
                    kind
                    url
                    size
                    height
                    width
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`